<template>
  <v-app>
    <v-app-bar
      app
      dense
      dark
      color="primary"
    >
      <v-toolbar-title>Configuração do Áudio</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-card elevation="0">
        <v-card-text>
          <v-layout
            wrap
            style="padding: 0; margin: 0 auto"
          >
            <v-flex xs12>
              <v-text-field
                label="Carregar dados usando URL do Áudio"
                outlined
                dense
                v-model="url"
              />
            </v-flex>

            <v-flex xs12>
              <v-text-field
                label="Título"
                outlined
                dense
                v-model="data.title"
                :disabled="!data.audioId"
              />
            </v-flex>

            <v-flex xs12>
              <v-text-field
                label="Descrição"
                outlined
                dense
                v-model="data.caption"
                :disabled="!data.audioId"
              />
            </v-flex>
          </v-layout>

          <v-row class="espacamento">
            <v-col cols="12">
              <caption>Padding</caption>
            </v-col>

            <v-col class="py-0">
              <FieldNumber
                v-model="data.paddingTop"
                label="Topo"
              />
            </v-col>

            <v-col class="py-0">
              <FieldNumber
                v-model="data.paddingLeft"
                label="Esquerda"
              />
            </v-col>

            <v-col class="py-0">
              <FieldNumber
                v-model="data.paddingRight"
                label="Direita"
              />
            </v-col>

            <v-col class="py-0">
              <FieldNumber
                v-model="data.paddingBottom"
                label="Abaixo"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import { requests } from '@/plugins/Amplify'
import { formatSecs } from '@/utils'
import FieldNumber from '@/components/fields/FieldNumber'

export default {
  components: {
    FieldNumber
  },

  name: 'PageAudioConfig',

  props: {
    value: {}
  },

  data () {
    const url = ''
    const data = clone(this.value || {})
    if (!data.xMargin) data.xMargin = 'fpe-margin-0'
    if (!data.yMargin) data.yMargin = 'fpe-margin-0'
    return { data, url }
  },

  methods: {
    loadInfoAudio () {
      const vm = this
      const url = String(vm.url || '').trim()
      if (!url) return

      const clearURL = () => {
        vm.$nextTick().then(() => {
          vm.url = ''
        })
      }

      let matches = /\/reproduzir\/(\d+)/ig.exec(url)
      if (!matches) matches = /\/v1\/audios\/download\?id=(\d+)/ig.exec(url)
      if (!matches) return clearURL()

      const audioId = matches[1]
      if (isNaN(audioId)) return clearURL()
      if (String(audioId) === vm.data.audioId) return

      requests.getAudios({ id: audioId }).then(({ rows }) => {
        const audio = rows.pop()
        if (!audio) return clearURL()

        const url = `/reproduzir/${audioId}`
        const title = String(audio.assunto)
        let caption = formatSecs(audio.duracao)

        const grauSigla = String(audio.grauSigla || '').trim()
        if (grauSigla) caption += ` | ${grauSigla} | `

        const pessoa = String(audio.autorNome || audio.dirigenteNome || audio.oradorNome || '').trim()
        if (pessoa) caption += ` | ${pessoa} | `

        const sessao = String(audio.sessaoDescricao || audio.tipoDescricao || '').trim()
        if (sessao) caption += ` | ${sessao} | `

        caption = caption.replaceAll('|  |', '|').replaceAll('| |', '|').replaceAll('||', '|').trim()
        if (caption.at(-1) === '|') caption = caption.substring(0, caption.length - 1)

        vm.data = Object.assign({}, vm.data, { title, caption, audioId, url })
      }).catch(() => clearURL())
    }
  },

  watch: {
    url () {
      this.loadInfoAudio()
    },

    data: {
      deep: true,
      handler (data) {
        const vm = this
        if (isEqual(data, vm.value)) return
        vm.$emit('input', clone(data))
      }
    },

    value: {
      deep: true,
      handler (value) {
        const vm = this
        if (isEqual(value, vm.item)) return
        vm.data = clone(value || {})
      }
    }
  }
}

const clone = (source) => JSON.parse(JSON.stringify(source))
const isEqual = (source, target) => JSON.stringify(source) === JSON.stringify(target)

</script>
