var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","dense":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Configuração Vertical")])],1),_c('v-main',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('v-layout',{staticStyle:{"padding":"0","margin":"0 auto"},attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-select',{attrs:{"dense":"","outlined":"","label":"Alinhamento","items":[
                { value: 'fpe-justify-start', text: 'Alinhar Topo' },
                { value: 'fpe-justify-end', text: 'Alinhar Final' },
                { value: 'fpe-justify-center', text: 'Centralizar' },
                { value: 'fpe-justify-between', text: 'Espaçar Entre' },
                { value: 'fpe-justify-around', text: 'Espaçar ao Redor' },
                { value: 'fpe-justify-evenly', text: 'Espaçar Uniformemente' }
              ]},model:{value:(_vm.data.justify),callback:function ($$v) {_vm.$set(_vm.data, "justify", $$v)},expression:"data.justify"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-select',{attrs:{"dense":"","outlined":"","label":"Coluna","items":[
                { value: 'fpe-col-auto', text: 'Tamanho do Conteúdo' },
                { value: 'fpe-col', text: 'Espaço restante' },
                { value: 'fpe-col-1', text: '1/12' },
                { value: 'fpe-col-2', text: '2/12' },
                { value: 'fpe-col-3', text: '25%' },
                { value: 'fpe-col-4', text: '4/12' },
                { value: 'fpe-col-5', text: '5/12' },
                { value: 'fpe-col-6', text: '50%' },
                { value: 'fpe-col-7', text: '7/12' },
                { value: 'fpe-col-8', text: '8/12' },
                { value: 'fpe-col-9', text: '75%' },
                { value: 'fpe-col-10', text: '10/12' },
                { value: 'fpe-col-11', text: '11/12' },
                { value: 'fpe-col-12', text: '100%' },
              ]},model:{value:(_vm.data.col),callback:function ($$v) {_vm.$set(_vm.data, "col", $$v)},expression:"data.col"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }