<template>
  <v-app>
    <v-app-bar
      app
      dense
      dark
      color="primary"
    >
      <v-toolbar-title>Configuração de Expansão</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-card elevation="0">
        <v-card-text>
          <v-row class="espacamento">
            <v-col cols="12">
              <caption>Padding</caption>
            </v-col>

            <v-col class="py-0">
              <FieldNumber
                v-model="data.paddingTop"
                label="Topo"
              />
            </v-col>

            <v-col class="py-0">
              <FieldNumber
                v-model="data.paddingLeft"
                label="Esquerda"
              />
            </v-col>

            <v-col class="py-0">
              <FieldNumber
                v-model="data.paddingRight"
                label="Direita"
              />
            </v-col>

            <v-col class="py-0">
              <FieldNumber
                v-model="data.paddingBottom"
                label="Abaixo"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col xs="12">
              <PageEditor
                v-model="data.text"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>

import FieldNumber from '@/components/fields/FieldNumber'
const PageEditor = () => import('./PageEditor')

export default {
  name: 'PageExpandConfig',

  components: {
    PageEditor,
    FieldNumber
  },

  props: {
    value: {}
  },

  data () {
    const data = clone(this.value || {})
    return { data }
  },

  watch: {
    data: {
      deep: true,
      handler (data) {
        const vm = this
        if (isEqual(data, vm.value)) return
        vm.$emit('input', clone(data))
      }
    },

    value: {
      deep: true,
      handler (value) {
        const vm = this
        if (isEqual(value, vm.item)) return
        vm.data = clone(value || {})
      }
    }
  }
}

const clone = (source) => JSON.parse(JSON.stringify(source))
const isEqual = (source, target) => JSON.stringify(source) === JSON.stringify(target)

</script>
