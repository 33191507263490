<template>
  <v-app>
    <v-app-bar
      app
      dense
      dark
      color="primary"
    >
      <v-toolbar-title>Configuração de Imagem</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-card
        elevation="0"
        v-if="isLoading"
        style="display: flex; height: 100%; justify-content: center; align-items: center;"
      >
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        />
      </v-card>

      <v-card
        elevation="0"
        v-else
      >
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                readonly
                outlined
                hide-details
                label="Caminho da Imagem"
                type="text"
                v-model="data.img"
              >
                <template v-slot:prepend>
                  <v-file-input
                    dense
                    x-small
                    prepend-icon="mdi-cloud-upload"
                    class="ignorepaddings"
                    hide-input
                    hide-details
                    v-model="localImage"
                    accept="image/png,image/jpeg,image/jpg"
                  />
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="data.img">
            <v-col cols="12">
              <v-img
                :src="data.img"
                contain
                :max-width="data.maxWidth"
                :max-height="150"
                style="border-radius: 5px; margin: 0 auto;"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Largura Máxima"
                outlined
                dense
                hide-details
                v-model="data.maxWidth"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                label="Altura Máxima"
                outlined
                dense
                hide-details
                v-model="data.maxHeight"
              />
            </v-col>
          </v-row>

          <v-row class="espacamento">
            <v-col cols="12">
              <caption>Padding</caption>
            </v-col>

            <v-col class="py-0">
              <FieldNumber
                v-model="data.paddingTop"
                label="Topo"
              />
            </v-col>

            <v-col class="py-0">
              <FieldNumber
                v-model="data.paddingLeft"
                label="Esquerda"
              />
            </v-col>

            <v-col class="py-0">
              <FieldNumber
                v-model="data.paddingRight"
                label="Direita"
              />
            </v-col>

            <v-col class="py-0">
              <FieldNumber
                v-model="data.paddingBottom"
                label="Abaixo"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <PageEditor
                v-model="data.caption"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import slug from 'slug'
import FieldNumber from '@/components/fields/FieldNumber'
import { requests } from '@/plugins/Amplify'
const PageEditor = () => import('./PageEditor')

export default {
  name: 'PageImageConfig',

  components: {
    PageEditor,
    FieldNumber
  },

  props: {
    value: {}
  },

  data () {
    const data = clone(this.value || {})
    if (data.maxWidth === undefined) data.maxWidth = '197px'

    return {
      data,
      isLoading: false,
      localImage: null
    }
  },

  methods: {
    async loadImageFromFile (file) {
      const vm = this
      vm.isLoading = true

      const signResult = await requests.getImageUploadUrl({ name: toSlug(file.name) })

      const formData = new FormData()
      Object.entries(signResult.fields).forEach(([k, v]) => formData.append(k, v))
      formData.append('file', file)

      const xhr = new XMLHttpRequest()
      xhr.open('POST', signResult.url, true)

      xhr.upload.addEventListener('loadend', (res) => {
        console.log(res)
        vm.isLoading = false
        vm.data.img = `${signResult.url}/${signResult.fields.key}`
      }, false)

      xhr.send(formData)
    }
  },

  watch: {
    localImage (localImage) {
      if (localImage) this.loadImageFromFile(localImage)
    },

    data: {
      deep: true,
      handler (data) {
        const vm = this
        if (isEqual(data, vm.value)) return
        vm.$emit('input', clone(data))
      }
    },

    value: {
      deep: true,
      handler (value) {
        const vm = this
        if (isEqual(value, vm.item)) return
        vm.data = clone(value || {})
      }
    }
  }
}

const clone = (source) => JSON.parse(JSON.stringify(source))
const isEqual = (source, target) => JSON.stringify(source) === JSON.stringify(target)

const toSlug = (str) => {
  const ext = String(str || '').trim().split('.').pop()
  const names = slug(str || '', '-').replace(/[ª/\\]/g, '-').replace(/\s+/g, '-').replace(/-+/g, '-').trim().split('')
  names.splice(names.length - ext.length, 0, '.')
  return names.join('')
}

</script>

<style lang="sass">
.mycard
  width: 100%

.myfooter
  outline: 1px dashed #0094d9
</style>
