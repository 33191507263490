var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","dense":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Configuração Horizontal")])],1),_c('v-main',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('v-layout',{staticStyle:{"padding":"0","margin":"0 auto"},attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-select',{attrs:{"dense":"","outlined":"","hide-details":"","label":"Alinhamento","items":[
                { value: 'fpe-justify-start', text: 'Alinhar à Esquerda' },
                { value: 'fpe-justify-end', text: 'Alinhar à Direita' },
                { value: 'fpe-justify-center', text: 'Centralizar' },
                { value: 'fpe-justify-between', text: 'Espaçar Entre' },
                { value: 'fpe-justify-around', text: 'Espaçar ao Redor' },
                { value: 'fpe-justify-evenly', text: 'Espaçar Uniformemente' }
              ]},model:{value:(_vm.data.justify),callback:function ($$v) {_vm.$set(_vm.data, "justify", $$v)},expression:"data.justify"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }