<template>
  <component
    :is="configType"
    v-if="config"
    v-model="config.data"
  />
</template>

<script>
import Vue from 'vue'
import PageAudioConfig from '@/components/page/PageAudioConfig'
import PageColumnsConfig from '@/components/page/PageColumnsConfig'
import PageExpandConfig from '@/components/page/PageExpandConfig'
import PageImageConfig from '@/components/page/PageImageConfig'
import PageRowsConfig from '@/components/page/PageRowsConfig'
import PageTextConfig from '@/components/page/PageTextConfig'

Vue.component('PageAudioConfig', PageAudioConfig)
Vue.component('PageColumnsConfig', PageColumnsConfig)
Vue.component('PageExpandConfig', PageExpandConfig)
Vue.component('PageImageConfig', PageImageConfig)
Vue.component('PageRowsConfig', PageRowsConfig)
Vue.component('PageTextConfig', PageTextConfig)

export default {
  name: 'ConfigDragDrop',

  data () {
    const config = null
    const dataRef = null
    return { config, dataRef }
  },

  mounted () {
    const vm = this
    const params = Object.assign({}, vm.$route.params)
    const { configId, configType } = params
    vm.configType = `${configType}Config`
    window.addEventListener('message', vm.onMessage, true)
    window.opener.postMessage(JSON.stringify({ configId, action: 'isOpened' }), window.opener.location.origin)
  },

  beforeDestroy () {
    const vm = this
    window.removeEventListener('message', vm.onMessage, true)
  },

  methods: {
    onMessage (event) {
      const vm = this
      const params = Object.assign({}, vm.$route.params)
      const { configId } = params
      if (event.origin !== window.opener.location.origin) return
      const data = parseJson(event.data)
      if (data?.item?.id !== configId) return
      if (data.action === 'update') return vm.updateData(data.item)
    },

    updateData (item) {
      const vm = this
      if (isEqual(vm.config?.data || null, item?.data || null)) return

      if (vm.config) {
        vm.config = Object.assign({}, vm.config, { data: item.data })
      } else {
        vm.config = clone(item)
      }

      vm.dataRef = clone(vm.config.data)
    }
  },

  watch: {
    config: {
      deep: true,
      handler (config) {
        const vm = this
        if (isEqual(config?.data || null, vm.dataRef || null)) return

        const { configId } = vm.$route.params
        window.opener.postMessage(JSON.stringify({ configId, action: 'update', item: config }), window.opener.location.origin)
      }
    }
  }
}

const isEqual = (source, target) => JSON.stringify(source) === JSON.stringify(target)
const clone = (source) => JSON.parse(JSON.stringify(source))

const parseJson = (str) => {
  if (!str) return null

  try {
    return JSON.parse(str)
  } catch {
    return null
  }
}

</script>
