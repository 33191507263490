<template>
  <v-row
    align="center"
    no-gutters
  >
    <v-col cols="auto">
      <v-col class="pa-0">
        <v-btn
          icon
          dense
          @click="incVal"
          class="pa-0"
        >
          <v-icon
            color="green"
            dense
            class="pa-0"
          >
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>

      <v-col class="pa-0">
        <v-btn
          icon
          dense
          @click="decVal"
          class="pa-0"
        >
          <v-icon
            color="red"
            dense
            class="pa-0"
          >
            mdi-minus
          </v-icon>
        </v-btn>
      </v-col>
    </v-col>

    <v-col
      class="max-width-number"
      ref="refInputDecimal"
    >
      <v-text-field
        outlined
        dense
        :label="label"
        hide-details
        v-model="str"
        suffix="rem"
        maxlength="5"
        pattern="[0-9,]*"
        inputmode="decimal"
      />
    </v-col>
  </v-row>
</template>

<script>
import numeral from '@/plugins/numeral'

export default {
  props: {
    value: {},
    label: {}
  },

  data () {
    const str = valToStr(this.value)
    const val = strToVal(str)
    return { str, val }
  },

  mounted () {
    const vm = this

    vm.$refs.refInputDecimal.querySelector('input').addEventListener('input', onlyDecimal)
  },

  methods: {
    incVal () {
      let val = (strToVal(this.str) || 0) + 0.3
      if (val > 12) val = 12
      if (val < 0) val = 0
      this.str = valToStr(val)
    },

    decVal () {
      let val = (strToVal(this.str) || 0) - 0.3
      if (val > 12) val = 12
      if (val < 0) val = 0
      this.str = valToStr(val)
    }
  },

  watch: {
    str (str) {
      this.$nextTick().then(() => {
        const val = strToVal(str)
        if (val === this.val) return
        this.$emit('input', val)
      })
    },

    value (value) {
      this.$nextTick().then(() => {
        const str = valToStr(value)
        console.log({ str })
        if (str === this.str) return
        this.str = str
      })
    }
  }
}

function onlyDecimal () {
  let str = String(this.value || '').trim().replace()
  let vals = str.replace(/[^0-9,]/g, '').split(',')

  if (vals.length > 2) vals.splice(2, 10)
  str = vals.join(',')

  vals = str.split(',')

  if (vals.length > 1 && vals[1].length > 1) {
    vals[1] = vals[1][0]
    str = vals.join(',')
  }

  const val = strToVal(str)
  if (val > 12) str = '12,0'
  if (val < 0) str = ''

  if (str !== this.value) this.value = str
}

const valToStr = (val) => {
  const isEmpty = [null, undefined, ''].includes(val)
  if (isEmpty) return ''
  if (isNaN(val)) return ''

  const num = Number(val)
  if (num < 0) return ''

  return numeral(num).format('0.0')
}

const strToVal = (str) => {
  const isEmpty = [null, undefined, ''].includes(str)
  if (isEmpty) return null
  if (str[str.length - 1] === ',') str = str.replaceAll(',', '').trim()
  return numeral(Number(str.replaceAll('.', '').replace(',', '.'))).value()
}

</script>

<style lang="sass">
.max-width-number
  max-width: 120px

.espacamento
  border: 1px solid #00000061
  border-radius: 4px
  margin: 0
</style>
