<template>
  <v-app>
    <v-app-bar
      app
      dense
      dark
      color="primary"
    >
      <v-toolbar-title>Configuração Vertical</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-card elevation="0">
        <v-card-text>
          <v-layout
            wrap
            style="padding: 0; margin: 0 auto"
          >
            <v-flex xs12>
              <v-select
                dense
                outlined
                v-model="data.justify"
                label="Alinhamento"
                :items="[
                  { value: 'fpe-justify-start', text: 'Alinhar Topo' },
                  { value: 'fpe-justify-end', text: 'Alinhar Final' },
                  { value: 'fpe-justify-center', text: 'Centralizar' },
                  { value: 'fpe-justify-between', text: 'Espaçar Entre' },
                  { value: 'fpe-justify-around', text: 'Espaçar ao Redor' },
                  { value: 'fpe-justify-evenly', text: 'Espaçar Uniformemente' }
                ]"
              />
            </v-flex>

            <v-flex xs12>
              <v-select
                dense
                outlined
                v-model="data.col"
                label="Coluna"
                :items="[
                  { value: 'fpe-col-auto', text: 'Tamanho do Conteúdo' },
                  { value: 'fpe-col', text: 'Espaço restante' },
                  { value: 'fpe-col-1', text: '1/12' },
                  { value: 'fpe-col-2', text: '2/12' },
                  { value: 'fpe-col-3', text: '25%' },
                  { value: 'fpe-col-4', text: '4/12' },
                  { value: 'fpe-col-5', text: '5/12' },
                  { value: 'fpe-col-6', text: '50%' },
                  { value: 'fpe-col-7', text: '7/12' },
                  { value: 'fpe-col-8', text: '8/12' },
                  { value: 'fpe-col-9', text: '75%' },
                  { value: 'fpe-col-10', text: '10/12' },
                  { value: 'fpe-col-11', text: '11/12' },
                  { value: 'fpe-col-12', text: '100%' },
                ]"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'PageColumnsConfig',

  props: {
    value: {}
  },

  data () {
    const url = ''
    const data = clone(this.value || {})
    return { data, url }
  },

  watch: {
    data: {
      deep: true,
      handler (data) {
        const vm = this
        if (isEqual(data, vm.value)) return
        vm.$emit('input', clone(data))
      }
    },

    value: {
      deep: true,
      handler (value) {
        const vm = this
        if (isEqual(value, vm.item)) return
        vm.data = clone(value || {})
      }
    }
  }
}

const clone = (source) => JSON.parse(JSON.stringify(source))
const isEqual = (source, target) => JSON.stringify(source) === JSON.stringify(target)

</script>
